import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Box, Image } from "rebass"
import ModelList from '../components/model-list/model-list'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import "../assets/scss/app.scss";
function ModelTemplate(props) {
    const data = props.data.contentfulModel
    const brand = data.brand ? data.brand[0] : null;
    return (
      <Layout>
        <Box
          sx={{
            maxWidth: 512,
            mx: "auto",
            px: 3,
          }}
        >
          <section>
            <h1>
              {" "}
              {brand ? brand.name : ""} {data.name} Philippines 2019
            </h1>
            <h4>Price starts at 299,999</h4>
            <Carousel style>
              <div>
                <img src={data.mainImage}  />
              </div>
              <div>
                <img src={data.mainImage}  />
              </div>
              <div>
                <img src={data.mainImage}  />
              </div>
            </Carousel>

            <p>
              Experience impeccable fuel efficiency with the New Toyota Vios. Now
              with Dual VVT-i engine + 7-speed sports sequential transmission (as
              seen in the other Premium Toyota Models).
              {/* // TODO */}
            {/* {data.description.description} */}
            </p>

          </section>

        </Box>
      </Layout>
    )
}
export default ModelTemplate
export const pageQuery = graphql`
         query($id: String) {
           contentfulModel(id: { eq: $id }) {
             name
             slug
             mainImage
              brand {
                name
              }
           }
         }
       `
